import { API_URL} from './types';
import Axios from 'axios';

export const getWarehousesOrigin = (query = undefined) => {
  const url = `${API_URL}/api/warehouses_origin?${query ? `query=${query}` : ''}`;
  return Axios.get(url, { headers:{'AUTH-TOKEN': localStorage.getItem('token')} })
    .then(({ data }) => data);
};

export const getWarehousesDestination = (query = undefined) => {
  const url = `${API_URL}/api/warehouses_destination?${query ? `query=${query}` : ''}`;
  return Axios.get(url, { headers:{'AUTH-TOKEN': localStorage.getItem('token')} })
    .then(({ data }) => data);
};

export const getAlternatives = ({sku, origin, destination, pallets}) => {
  const url = `${API_URL}/api/alternatives?sku=${sku}&origin=${origin}&destination=${destination}&pallets=${pallets}`;
  return Axios.get(url, { headers:{'AUTH-TOKEN': localStorage.getItem('token')} }).then(({data}) => {
    return data
  })
};

export const getSku = (query) => {
  const url = `${API_URL}/api/sku?query=${query}`;
  return Axios.get(url, { headers:{'AUTH-TOKEN': localStorage.getItem('token')} }).then(({data}) => {
    return data
  })
};

export const setReplacements = (missing, replacements) => {
  const url = `${API_URL}/api/replace`;
  const headers = { headers:{'AUTH-TOKEN': localStorage.getItem('token')} };
  const body = { missing, replacements };
  return Axios.post(url, body, headers).then(({data}) => {
    return data
  })
};
