import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Spinner, Form, Button, FormGroup, Label, Input, Alert} from 'reactstrap';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { signIn, googleSignIn, isSignIn } from '../../redux/actions/auth';

function SignIn() {
  const dispatch = useDispatch();
  const response = useSelector( state => state.auth.auth);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    document.title = 'CCU - Inicio de Sesión';
    setLoading(false);
    isSignIn()
      .then((isSignIn) => {
        if (isSignIn) window.location.href = '/stock'
      });
  }, []);
  
  useEffect(() => {
    if (typeof response !== 'undefined') {
      if (response.status === 'success') {
        window.location.href = '/stock'
      } else {
        setAlertType(response.status);
        setAlertMessage('Usuario o contraseña incorrectas');
        setShowAlert(true);
        setTimeout(() => {  setShowAlert(false) }, 5000);
      }
      setLoading(false);
    }
  }, [response]);

  const onDismiss = () => setShowAlert(false);
  
  const onFormSubmit = (e) => {
    setLoading(true)
    e.preventDefault();
    dispatch(signIn(email, password));
  };

  const onGoogleSubmit = (response) => {
    console.log({ response });
    dispatch(googleSignIn(response.credential));
  };

  return (
    <>
      <Container>
        <Alert color={alertType} isOpen={showAlert} toggle={onDismiss}>
          {alertMessage}
        </Alert>  
      </Container>
      <Container>
        <Form onSubmit={(e) => onFormSubmit(e)}>
          <FormGroup>
            <Label for="email">Usuario</Label>
            <Input type="text" placeholder="Usuario..." disabled={loading} onChange={(e) => setEmail(e.target.value)}/>
          </FormGroup>
          <FormGroup>
            <Label for="password">Contraseña</Label>
            <Input type="password" placeholder="· · · · · ·" disabled={loading} onChange={(e) => setPassword(e.target.value)}/>
          </FormGroup>
          <Button className='btn btn-primary' disabled={loading}>{!loading? ("Ingresar") : (<Spinner size="sm" />)}</Button>
        </Form>

        <div style={{ textAlign: 'center', width: '250px', margin: '10px auto' }}>
          <GoogleOAuthProvider
            clientId="308825059989-q6n8avsaibugo8o8c6ql00bgdfpn60bm.apps.googleusercontent.com"
          >
            <GoogleLogin
              locale="es_CL"
              width={250}
              onSuccess={onGoogleSubmit}
              onError={() => {
                console.log('Login Failed');
              }}
            />
          </GoogleOAuthProvider>

        </div>


      </Container>
    </>
  );
}

export default SignIn;