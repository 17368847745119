import React from 'react';
import { connect } from 'react-redux';
import './Layout.scss';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

const Layout = (props) => {
  return (
    <>
      <Header />
      <div className="content"  style={{ marginTop: 0, minHeight: '50vh' }}>
        {props.children}
      </div>
      <Footer fluid/>
    </>
  );
};

const mapStateToProps = (state) => {
  return {  };
};

export default connect(mapStateToProps)(Layout);
