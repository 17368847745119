import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Form } from 'reactstrap';
import Spinner from 'reactstrap/lib/Spinner';
import { createUser } from '../../redux/actions/auth';


const CreateUserModal = (props) => {
    const {
      className,
      action,
    } = props;
  
    const [modal, setModal] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const toggle = () => setModal(!modal);
    const doCreateUser = async () => {
        setLoading(true);
        await createUser(username, password);
        setLoading(false);
        action()
        toggle()
    }
  
    return (
        <div>
            <Form inline onSubmit={(e) => e.preventDefault()}>
                <Button color="success" onClick={toggle}><i className="fa fa-plus"/></Button>
            </Form>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Crear Usuario</ModalHeader>
                <ModalBody>
                    <Input type="text" onChange={(e) => {setUsername(e.target.value)}} placeholder="Nombre de usuario" />
                    <Input type="text" onChange={(e) => {setPassword(e.target.value)}} placeholder="Clave..." />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={doCreateUser}>{!loading ? ("Guardar") : (<Spinner size="sm"></Spinner>)}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
export default CreateUserModal;
