import React, {useState, useEffect} from 'react';
import Logo from '../../assets/img/logo-color.png'
import { isSignIn } from '../../redux/actions/auth';

const Header = (props) => {
  const [signed, setSigned] = useState(true);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState('user');

  useEffect(() => {
    setLoading(true)
    isSignIn()
      .then((isSigned) => {
        if (isSignIn) {
          setRole(isSigned.role);
        } else {
          setSigned(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const logOut = () => {
      localStorage.setItem('token', null);
      window.location.href = '/sign-in'
  }
  const goAdmin = () => {
    window.location.href = '/admin'
  }

  return (
    <nav className="navbar navbar-expand-lg bg-white">
    <div className="container">
    <img style={{height: '30px'}} className="mx-auto" src={Logo} alt="ccu logo"></img>

    { loading ? (<></>) : (
      <div className="navbar-collapse">
          {
            signed ? (
              <ul className="navbar-nav">
                {
                  role === 'admin' ? (
                    <li className="nav-item">
                      <a className="nav-link text-center" onClick={() => {goAdmin()}} href="#!">Admin <span className="sr-only">(current)</span></a>
                    </li>
                  ) :
                  (
                    <></>
                  )
                }
                {
                  role === 'admin' ? (
                    <li className="nav-item">
                      <a className="nav-link text-center" onClick={() => {window.location.href = '/stock'}} href="#!">Stock <span className="sr-only">(current)</span></a>
                    </li>
                  ) :
                  (
                    <></>
                  )
                }
                <li className="nav-item">
                  <a className="nav-link text-center" onClick={() => {logOut()}} href="#!">Salir <span className="sr-only">(current)</span></a>
                </li>
              </ul>
            ) : (
              
              <></>
              
            )
            
          }
        </div>
        )
      }
      </div>
      
    </nav>  
  );
};

export default Header;
