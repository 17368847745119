import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import Spinner from 'reactstrap/lib/Spinner';
import { updateUser } from '../../redux/actions/auth';


const UpdateUserModal = (props) => {
    const {
      className,
      username
    } = props;
  
    const [modal, setModal] = useState(false);
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const toggle = () => setModal(!modal);
    const doUpdateUser = async () => {
        setLoading(true);
        await updateUser(username, password);
        setLoading(false);
        toggle();
    }
  
    return (
        <div className={'float-left'}>
            <Button color="warning" className="btn btn-sm" onClick={toggle}><i className="fa fa-edit"></i></Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Crear Usuario</ModalHeader>
                <ModalBody>
                    <Input type="text" onChange={(e) => {setPassword(e.target.value)}} placeholder="Clave..." />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={doUpdateUser}>{!loading ? ("Guardar") : (<Spinner size="sm"></Spinner>)}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
export default UpdateUserModal;
