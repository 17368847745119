import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Spinner, Table, Button, Input } from 'reactstrap';
import { isSignIn, getUsers, deleteUser } from '../../redux/actions/auth';
import CreateUserModal from './CreateUserModal'
import UpdateUserModal from './UpdateUserModal'
function SignIn() {
    const response = useSelector( state => state.auth.auth);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');

    useEffect(() => {
      document.title = 'CCU - Admin';
      isSignIn().then((isSigned) => {
        if (isSigned.role !== 'admin'){
          window.location.assign('/stock')
          return
        }
        getUsers().then(data => setData(data));
        setLoading(false);
      })
    }, []);
    
    useEffect(() => {
      if(typeof response !== 'undefined') {
        if(response.status === 'success') {
          window.location.href = '/stock'
        }
        setLoading(false);
      }

    }, [response]);

    const spinnerStyle = { position: "fixed", top: "47%", left: "47%"};
    const reload = async () => {
      setData(await getUsers());
    }

    return loading?  (
      <Container><Spinner style={spinnerStyle} /></Container>
    ) :(
      <>
        <Container styles={{overflowY: "scroll"}}>
          <CreateUserModal action={reload} />  
          <Input type="text" onChange={(e) => {setFilter(e.target.value)}} placeholder="Filtrar por usuario...." />
          <Table bordered style={{marginBottom: "100px"}}>
          <thead>
            <tr>
                <th>#</th>
                <th>Usuario</th>
                <th>Accion</th>
            </tr>
        </thead>
        <tbody>
            {
                data.map((user, index) =>  {
                 if(user.username.includes(filter)){
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{user.username}</td>
                            <td>
                              <UpdateUserModal username={user.username} action={reload} />  {' '}
                              <Button color="danger" onClick={async ()  => {await deleteUser(user.username);reload();}} className="btn btn-sm">
                                <i className="fa fa-times"></i>
                              </Button>
                            </td>
                        </tr>
                    )}
                  else{
                    return(<tr key={index}></tr>)
                  }
                })
            }
        </tbody>
          </Table>
        </Container>
      </>
    );
}

export default SignIn;