import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import './App.scss';
import Layout from './layout/Layout';
import Stock from './pages/Stock/Stock';
import SignIn from './pages/SignIn/SignIn';
import Admin from './pages/Admin/Admin';

function App() {
  let routes = (
    <Switch>
      <Route path="/admin" component={Admin} />
      <Route path="/stock" component={Stock} />
      <Route path="/sign-in" component={SignIn} />
      <Route exact path="/">
        <Redirect to="/sign-in" />
      </Route>
    </Switch>
  );

  return <Layout>{ routes }</Layout>;
}

export default App;
