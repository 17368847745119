import { types } from '../actions/types';

export const authReducer = (state = {}, action) => {
    switch (action.type) {
        case types.signIn:
            return {
              ...state,
              auth: action.payload
            };
        default:
            return state;
    }
}