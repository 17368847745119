import { types, API_URL } from './types';
import Axios from 'axios';

export const signIn = (email, password) => { 
  const url = `${API_URL}/api/login`
  return (dispatch) => {
    let outputData = {};
    let params = {
        username: email,
        password: password
    };
    let headers = {
      "Access-Control-Allow-Origin": "*"
    }
    Axios.post(url, params, headers).then(({ data }) => {
      console.log(data);
      if (!data.logged) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Invalid credentials!';
      }
      else {
        localStorage.setItem('token', data['AUTH-TOKEN']);
        outputData['status'] = 'success';
      }
      dispatch(setSignIn(outputData));
    })
  }
};

export const googleSignIn = (token) => { 
  const url = `${API_URL}/api/google_login`
  return (dispatch) => {
    const outputData = {};
    const params = { token };
    const headers = { "Access-Control-Allow-Origin": "*" };
    Axios.post(url, params, headers).then(({ data }) => {
      console.log(data);
      if (!data.logged) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Invalid credentials!';
      }
      else {
        localStorage.setItem('token', data['AUTH-TOKEN']);
        outputData['status'] = 'success';
      }
      dispatch(setSignIn(outputData));
    })
  }
};

export const getUsers = async () =>{
  const url = `${API_URL}/api/get_users`
  let headers = {headers:{"AUTH-TOKEN": localStorage.getItem("token")}}
  return await Axios.get(url, headers).then(({ data }) => {
    
    if (data.error) {
      return false
    }
    else {
      return data;
    }
  })
}

export const createUser = async (username, password) =>{
  const url = `${API_URL}/api/sign_up`
  let params = {'username': username, 'password': password};
  let headers = {headers:{"AUTH-TOKEN": localStorage.getItem("token")}}
  return await Axios.post(url, params, headers).then(({ data }) => {
    
    if (data.error) {
      return false
    }
    else {
      return data
    }
  })
}

export const isSignIn = async () =>{
  const url = `${API_URL}/api/is_logged`
  let params = {};
  let headers = {headers:{"AUTH-TOKEN": localStorage.getItem("token")}}
  return await Axios.post(url, params, headers).then(({ data }) => {
    if (data.error) {
      return false
    }
    else {
      return data
    }
  })
}

export const deleteUser = async (username) =>{
  const url = `${API_URL}/api/delete_user`
  let params = {username: username};
  let headers = {headers:{"AUTH-TOKEN": localStorage.getItem("token")}}
  return await Axios.post(url, params, headers).then(({ data }) => {
    
    if (data.error) {
      return false
    }
    else {
      return data
    }
  })
}

export const updateUser = async (username, password) =>{
  const url = `${API_URL}/api/sign_up`
  let params = {username: username, password: password};
  let headers = {headers:{"AUTH-TOKEN": localStorage.getItem("token")}}
  return await Axios.put(url, params, headers).then(({ data }) => {
    
    if (data.error) {
      return false
    }
    else {
      return data
    }
  })
}

export const setSignIn = (data) => ({
    type: types.signIn,
    payload: data,
});
