export const API_URL = 'https://api.comodin.ccu.cl';
// export const API_URL = 'http://localhost:3000';
export const CRYPTO_API_URL = process.env.REACT_APP_ENV_CRYPTO_API_URL;
export const FM_API_URL = process.env.REACT_APP_ENV_FM_API;
export const FM_API_KEY = process.env.REACT_APP_API_KEY;


export const types = {
  changeLanguage: '[Lang] Change',
  getSentiment: '[API] getSentiment',
  getPrediction: '[API] getPrediction',
  signIn: '[API] SignIn',
  getCryptoNames: '[API] getCryptoNames',
  getCryptoValues: '[API] getCryptoValues',
  getCryptoProfile: '[FM_API] getCryptoProfile',
};
